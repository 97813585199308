import * as React from "react";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import Button from "../components/common/button/button";
import FourOhFour from "../images/icons/404/404.svg";
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <div className="flex flex-col flex-grow w-full h-full justify-center">
        <div className="flex flex-row justify-center pb-28">
          <svg
            width="194"
            height="69"
            viewBox="0 0 194 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.0286 55.3943V68.8H45.7543V55.3943H54.9829V45H45.7543V0.799988H32.7371L0 47.3314V55.3943H33.0286ZM14.3771 45L33.0286 17.2171V45H14.3771ZM96.5112 1C89.7868 1.00115 83.214 2.99628 77.6235 6.73295C72.033 10.4696 67.6761 15.7801 65.1036 21.9929C62.5312 28.2057 61.8587 35.042 63.1713 41.637C64.4839 48.2314 67.7225 54.2895 72.4778 59.0439C77.233 63.7983 83.2913 67.0357 89.8866 68.3472C96.4816 69.6587 103.318 68.9845 109.53 66.4111C115.742 63.8376 121.052 59.4799 124.788 53.8886C128.523 48.2973 130.517 41.7241 130.517 34.9997C130.506 25.985 126.92 17.3425 120.545 10.9686C114.169 4.59457 105.526 1.00955 96.5112 1ZM96.5112 63.5154V6.4903C100.29 6.43522 104.043 7.13209 107.55 8.54028C111.058 9.94846 114.251 12.0399 116.943 14.693C119.635 17.3462 121.773 20.508 123.232 23.9946C124.691 27.4813 125.442 31.2236 125.442 35.0027C125.442 38.7824 124.691 42.5246 123.232 46.0111C121.773 49.4982 119.635 52.6599 116.943 55.3132C114.251 57.9658 111.058 60.0573 107.55 61.4656C104.043 62.8738 100.29 63.5704 96.5112 63.5154ZM171.546 68.8V55.3943H138.517V47.3314L171.254 0.799988H184.272V45H193.5V55.3943H184.272V68.8H171.546ZM171.546 17.2171L152.894 45H171.546V17.2171Z"
              fill="url(#error_linear)"
            />
            <defs>
              <linearGradient
                id="error_linear"
                x1="193.5"
                y1="34.9"
                x2="3.75713e-06"
                y2="34.9"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF4041" />
                <stop offset="0.296875" stop-color="#FF6190" />
                <stop offset="0.635417" stop-color="#FF8B4B" />
                <stop offset="1" stop-color="#85D6F7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <p className="body-1 text-white text-center pt-8">
          Sorry, we can't find that page!
        </p>
        <div className="flex flex-row justify-center pt-64">
          <Button
            onClick={() => {
              navigate("/explore");
            }}
          >
            Explore albums
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
